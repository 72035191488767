export const isProduction = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_APP_ENV !== "Staging"
      ? true
      : false
    : false;
};

export const isStaging = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_APP_ENV !== "Staging"
      ? false
      : true
    : false;
};

export const isLocal = () => {
  return process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_APP_ENV !== "Staging"
      ? false
      : false
    : true;
};
